import { useContext } from "react";
import styled from "styled-components";
import { Button } from "rsuite";
import { useNavigate } from "react-router-dom";

import Card from "../components/Card";
import { AppContext } from "../contexts/AppContext";
import { EViewKeys } from "../types/enums";

import LBotImage from "../assets/images/L-BOT-LONG-TITLE.png";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .header-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
  }

  .text-wall {
    width: fit-content;
  }

  p {
    font-size: 14px;
    max-width: 520px;
    margin-bottom: 16px;
  }
`;

const ButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  width: 100%;
  max-width: 520px;
  & :first-child {
    margin-right: 10px;
  }
`;

const LandingPage = () => {
  const navigate = useNavigate();
  const { setCurrentView } = useContext(AppContext);

  const onNoConsentClick = () => {
    navigate("/elo-test");
    setCurrentView(EViewKeys.factorSelector);
  };
  const onConsentClick = () => {
    navigate("/elo-test");
  };

  return (
    <Card>
      <StyledContainer>
        <div className="header-section">
          {/* <h5>Behaviour Orientation Tool</h5>
          <h4>BOT</h4> */}
          <img height="110" src={LBotImage}></img>
        </div>

        <div className="text-wall">
          <p>
            We all get stressed and know we need to do things differently to
            feel better.
          </p>
          <p>
            Why don't we take the actions we know we should, even when we know
            precisely what actions we should take?
          </p>
          <p>
            It is typically due to prioritisation errors, being overwhelmed and
            an inability to focus on what is in our control.
          </p>
          <p>
            The BOT is designed to help you identify and rank the competing
            stressors from the different domains of your life.
          </p>
          <p>
            It will help you compare these factors against each other so you can
            understand the greatest drivers of stress, prioritise your efforts,
            and make an action plan to reduce your risk of burnout.
          </p>
          <p>
            You will be asked to choose six factors, each from the 'Mind, Body
            and Environment' drop-down menu, and rank the factors against each
            other.
          </p>

          <p>
            This paired comparison method is shown to be effective at working
            out which are your greatest stress drivers between factors from
            different life domains. The factor comparison section should take
            only 4 minutes.
          </p>
          <p>
            Once your results are displayed in a hierarchy, you can work out
            which are 'in your control' or 'out of your control' and use the
            information delivered in the sessions to make an action plan.
          </p>
          <p>
            All data is anonymised and will be stored securely so you can come
            back in later sessions, re-do the BOT, and see how effective your
            action plan was.
          </p>
          <p>
            All information you provide will remain confidential, will not be
            associated with your name, and will only be used as aggregated
            de-identified data for program improvement and research purposes.
          </p>
          <p>
            If you do not want your data stored, you can still participate.
            Press "I do not consent" You will not, however, have any data to
            participate in later sessions.
          </p>
          <p>If you are happy to participate press "I consent".</p>
        </div>

        <ButtonBar>
          <Button onClick={onNoConsentClick}>I do not consent</Button>
          <Button onClick={onConsentClick} appearance="primary">
            I consent
          </Button>
        </ButtonBar>
      </StyledContainer>
    </Card>
  );
};

export default LandingPage;
