import AnimationContainer from "../../components/AnimationContainer";
import { AppContext } from "../../contexts/AppContext";
import { Button } from "rsuite";
import Card from "../../components/Card";
import CategoryIcon from "../../components/CategoryIcon";
import FactorResults from "./FactorResults";
import styled from "styled-components";
import { useContext } from "react";
import { useEffect } from "react";

const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  width: 250px;
  transition: all 0.1s;
  cursor: pointer;
  position: relative;
  padding: 1em 1em;
  height: 160px;
  user-select: none;

  .rs-panel-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .hint-text {
    opacity: 0.6;
    font-size: 10px;
    text-align: center;
    margin-top: 0.6rem;
  }
  .icon {
    position: absolute;
    left: 15%;
    top: 15px;
  }

  :hover {
    transform: scale(1.1);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
`;

const Divider = styled.div`
  width: 1px;
  height: 80px;
  background: lightgrey;
  margin: 0 2%;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-left: 10px;
  }
`;

const ReferenceRanker = () => {
  const {
    resetFactors,
    // @ts-ignore
    referenceRanker,
    elo,
    setCurrentView,
  } = useContext(AppContext);

  const {
    count,
    pair,
    updateEloScores,
    undoLastSelection,
    canShowResults,
    countDown,
  } = referenceRanker;

  const [factorOne, factorTwo] = pair;

  useEffect(() => {
    if (localStorage.getItem("autoSelectSpeed")) {
      if (!canShowResults && factorOne && factorTwo) {
        setTimeout(() => {
          updateEloScores(factorOne, factorTwo);
        }, parseInt(localStorage.getItem("autoSelectSpeed")));
      }
    }
  }, [count, factorOne, factorTwo]);

  return (
    <>
      <AnimationContainer show={!canShowResults} placement="left">
        <Card centerHeader header="Which causes you the most stress?">
          <>
            <Container>
              {factorOne && factorTwo && (
                <>
                  <StyledCard
                    onClick={() => updateEloScores(factorOne, factorTwo)}
                  >
                    <CategoryIcon category={factorOne.category} />
                    {factorOne.label}
                    <p className="hint-text">{factorOne.hint}</p>
                  </StyledCard>
                  <Divider />
                  <StyledCard
                    onClick={() => updateEloScores(factorTwo, factorOne)}
                  >
                    <CategoryIcon category={factorTwo.category} />
                    {factorTwo.label}
                    <p className="hint-text">{factorTwo.hint}</p>
                  </StyledCard>
                </>
              )}
            </Container>

            <Container>
              {!!countDown && (
                <p>{countDown} more cards needed to display results.</p>
              )}
            </Container>

            <Buttons>
              {elo.last && (
                <Button onClick={undoLastSelection} appearance="primary">
                  Undo last selection
                </Button>
              )}
              <Button onClick={resetFactors} appearance="primary">
                Go back to Factor selection
              </Button>
            </Buttons>
          </>
        </Card>
      </AnimationContainer>

      <AnimationContainer show={canShowResults} placement="left">
        <Card centerHeader header="Top burnout factors">
          <Container>
            <FactorResults />
          </Container>
        </Card>
      </AnimationContainer>
    </>
  );
};

export default ReferenceRanker;
