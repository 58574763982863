import { createGlobalStyle } from "styled-components";

const units = "rem";

const addUnits = (obj) => {
  return Object.entries(obj).reduce((acc, next) => {
    const [key, value] = next;

    acc[key] = `${value}${units}`;

    return acc;
  }, {});
};

const theme = {
  colors: {
    primary: "var(--rs-btn-primary-bg)",
    sidenav: "#2f4050",
    topBar: "#f3f3f4",
    backgroundMain: "#f3f3f4",
    text: {
      secondary: "#b0b0b0",
    },
  },
  borders: { main: "1px solid #e7eaec" },
  spacings: addUnits({ xss: 0.5, xs: 0.75, s: 1, m: 1.25, l: 1.5 }),
  utils: {
    flex: {
      center: `display:flex;justify-content:center;align-items:center;`,
    },
  },
};

const ThemeGlobalStyle = createGlobalStyle`
body #root {
    --rs-sidenav-default-bg: ${theme.colors.sidenav};
    --rs-sidenav-default-text: white;
}
`;

export { theme, ThemeGlobalStyle };
