import React, { useState, useEffect } from "react";
import { EViewKeys } from "../types/enums";

export const useReferenceRanker = ({
  elo,
  selectedFactors,
  setEloScores,
  currentView,
}) => {
  const countToshow = 40;

  const [count, setCount] = useState(0);
  const [pair, setPair] = useState<any[]>([]);

  const getPair = ([f1, f2]: string[]) => {
    const factorOne = selectedFactors.find((f) => f.value === f1);
    const factorTwo = selectedFactors.find((f) => f.value === f2);

    return [factorOne, factorTwo];
  };

  const updateEloScores = (winner, loser) => {
    elo.updateScores(winner.value, loser.value);
    setEloScores({ ...elo.scores });

    setPair(getPair(elo.drawTwo()));
    setCount(count + 1);
  };

  useEffect(() => {
    if (!pair.length && currentView === EViewKeys.referenceRanker) {
      setPair(getPair(elo.drawTwo()));
    }
  }, [currentView]);

  const undoLastSelection = () => {
    setPair(getPair(elo.last.pair));
    setCount(count - 1);
    elo.undoLast();
    setEloScores({ ...elo.scores });
  };

  const canShowResults = count >= countToshow;

  let countDown = countToshow - count;

  return {
    updateEloScores,
    undoLastSelection,
    canShowResults,
    countDown,
    count,
    pair,
  };
};
