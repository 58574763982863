import React, { useEffect, useState } from "react";

import { AppContext } from "../contexts/AppContext";
import { EViewKeys } from "../types/enums";
import Elo from "../Elo";
import { api } from "../_config/api";
import { useReferenceRanker } from "./useReferenceRanker";

interface Props {
  children: React.ReactNode;
}

interface RawFactor {
  id: number;
  label: string;
  hint: string;
}
interface RawFactors {
  [key: string]: RawFactor[];
}
interface FactorCategories {
  [key: string]: {
    category: string;
    data: any[];
    selected: any[];
  };
}

const prepareFactorCategories = (rawFactors: RawFactors): FactorCategories => {
  return Object.entries(rawFactors).reduce((acc, [category, factors]) => {
    const data = factors.map((factor) => ({
      value: factor.id,
      ...factor,
      category,
    }));

    acc[category] = {
      category,
      data,
      selected: localStorage.getItem("preload")
        ? data.slice(0, 6).map((factor) => factor.value)
        : [],
    };

    return acc;
  }, {});
};

export const AppHandler = ({ children }: Props) => {
  const [currentView, setCurrentView] = useState<string>(
    EViewKeys.emailCapture,
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [factorCategories, setFactorCategories] = useState<object>(null);
  const [rawFactors, setRawFactors] = useState<object>(null);
  const [user, setUser] = useState<object>(null);

  const [behaviour, setBehaviour] = useState<string>("burnout");
  const [selectedFactors, setSelectedFactors] = useState<any[]>([]);
  const [eloScores, setEloScores] = useState<object>({});
  const [actionPlan, setActionPlan] = useState<object>({});
  const [resultId, setResultId] = useState<number>(null);
  const [resultDimensions, setResultDimensions] = useState<any[]>([]);
  const [factorsInControl, setFactorsInControl] = useState<number[]>([]);

  const [elo, setElo] = useState(new Elo([]));
  const referenceRanker = useReferenceRanker({
    elo,
    selectedFactors,
    setEloScores,
    currentView,
  });

  useEffect(() => {
    api.burnoutBot.get(`/behaviours/6/factors`).then((res) => {
      setRawFactors(res.data);
      setIsLoading(false);
      setFactorCategories(prepareFactorCategories(res.data));
    });
  }, []);

  useEffect(() => {
    if (resultId) {
      api.burnoutBot.patch(
        `/results/${resultId}/factors_in_control`,
        factorsInControl,
      );
    }
  }, [factorsInControl]);

  useEffect(() => {
    if (selectedFactors.length) {
      const allFactors = selectedFactors.map(({ value }) => value).flat();

      setElo(new Elo(allFactors));
    }
  }, [selectedFactors]);

  const sortedResults = selectedFactors
    .map((factor) => ({ ...factor, score: eloScores[factor.value] }))
    .sort((a, b) => b.score - a.score)
    .map((factor, index) => ({ ...factor, rank: index + 1 }));

  const submitResults = (freshResults) => {
    api.burnoutBot.post(`/results`, freshResults).then((res) => {
      // setRawFactors(res.data);
      // setIsLoading(false);
      // setFactorCategories(prepareFactorCategories(res.data));
      setResultId(res.data.result_id);
      setResultDimensions(
        Object.entries(res.data.dimensions).map(([title, score]) => ({
          title,
          score,
        })),
      );
    });
  };

  const submitEmail = (email) => {
    setIsSubmitting(true);

    api.burnoutBot.post(`/users`, { email }).then((res) => {
      setUser(res.data);
      setCurrentView(EViewKeys.factorSelector);
      setIsSubmitting(false);
    });
  };

  const submitActionPlan = () => {
    setIsSubmitting(true);

    const payload = {
      user,
      action_plan: {
        result_id: resultId,
        actions: Object.values(actionPlan),
      },
      result: {
        behaviour,
        factors: sortedResults.map((factor) => ({
          name: factor.label,
          score: factor.score,
          rank: factor.rank,
          id: factor.id,
        })),
      },
    };

    api.burnoutBot.post(`/email_results`, payload).then((res) => {
      setUser(res.data.user);
      setCurrentView(EViewKeys.contactDetails);
      setIsSubmitting(false);
    });
  };

  const value = {
    // state
    currentView,
    selectedFactors,
    rawFactors,
    factorCategories,
    elo,
    // setters
    setRawFactors,
    setCurrentView,
    setSelectedFactors,
    setFactorCategories,
    submitResults,
    resetFactors: () => {
      setSelectedFactors([]);
      setCurrentView(EViewKeys.factorSelector);
    },

    referenceRanker,
    sortedResults,
    actionPlan,
    setActionPlan,
    isSubmitting,
    submitEmail,
    submitActionPlan,
    factorsInControl,
    setFactorsInControl,
    resultDimensions,
    user,
  };

  // @ts-ignore
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
