import { Button, CheckPicker } from "rsuite";

import Card from "../../components/Card";
import PageNextIcon from "@rsuite/icons/PageNext";
import styled from "styled-components";
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import { EViewKeys } from "../../types/enums";

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 20px;
`;

const Categories = styled.div``;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FactorSelector = () => {
  const {
    setSelectedFactors,
    setCurrentView,
    factorCategories,
    setFactorCategories,
  } = useContext(AppContext);

  const selectLimit = 6;

  const isFullfilled = (cat) => cat.selected.length === selectLimit;

  const onChange = (categoryKey, values = []) => {
    const category = factorCategories[categoryKey];

    const nextCatValue = {
      ...category,
      selected: values,
    };

    if (values.length <= selectLimit) {
      setFactorCategories({
        ...factorCategories,
        [categoryKey]: nextCatValue,
      });
    }
  };

  const canStartRanking = !Object.values(factorCategories)
    .map(isFullfilled)
    .includes(false);

  const getDisabledOptions = ({ selected, data }) => {
    if (selected.length === selectLimit) {
      return data
        .filter((option) => !selected.includes(option.value))
        .map((option) => option.value);
    }

    return [];
  };

  const flatFactors = Object.entries(factorCategories).reduce(
    (acc, [key, categoryValue]) => {
      return [
        ...acc,
        ...categoryValue.data.filter((factor) =>
          categoryValue.selected.includes(factor.value)
        ),
      ];
    },
    []
  );

  return (
    <Card header="Please select 6 factors from each category that are likely to cause you stress.">
      <Categories>
        {Object.values(factorCategories).map((factor) => (
          <StyledLabel htmlFor={factor.category} key={factor.category}>
            <span>{factor.category}</span>
            <CheckPicker
              name={factor.category}
              value={factor.selected}
              size="sm"
              data={factor.data}
              onChange={(values) => onChange(factor.category, values)}
              disabledItemValues={getDisabledOptions(factor)}
            />
          </StyledLabel>
        ))}
      </Categories>
      <Buttons>
        <Button
          appearance="primary"
          onClick={() => {
            setSelectedFactors(flatFactors);
            // HACK: setTimeout to give the Elo time to be set before changing to the next view... This is dirty.
            setTimeout(() => {
              setCurrentView(EViewKeys.referenceRanker);
            }, 0);
          }}
          disabled={!canStartRanking}
        >
          Start Reference Ranking<PageNextIcon></PageNextIcon>
        </Button>
      </Buttons>
    </Card>
  );
};

export default FactorSelector;
