import { Button, Input } from "rsuite";

import { AppContext } from "../../contexts/AppContext";
import styled from "styled-components";
import { useContext } from "react";

const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  textarea {
    margin-bottom: 10px;
  }
`;

const CreateActionPlan = (props: any) => {
  // @ts-ignore
  const { actionPlan, setActionPlan, submitActionPlan } =
    useContext(AppContext);

  const actions = [1, 2, 3];

  const isDisabled = !actions.filter((action, index) => actionPlan[index])
    .length;

  return (
    <StyledContainer>
      {actions.map((action, index) => (
        <Input
          key={index}
          value={actionPlan[index]}
          onChange={(value) => {
            setActionPlan({ ...actionPlan, [index]: value });
          }}
          as="textarea"
          rows={3}
          placeholder={`action ${index + 1}`}
        ></Input>
      ))}

      <ButtonBar>
        <Button
          block
          appearance="primary"
          disabled={isDisabled}
          onClick={() => {
            submitActionPlan();
          }}
        >
          Submit Action Plan
        </Button>
      </ButtonBar>
    </StyledContainer>
  );
};

export default CreateActionPlan;
