import { useContext, useState, useEffect } from "react";
import { Input, Button } from "rsuite";
import styled from "styled-components";

import { AppContext } from "../../contexts/AppContext";

import Card from "../../components/Card";

const ButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;

  input {
    margin-right: 10px;
  }

  button {
    width: 100px;
  }
`;

const validateEmail = (email) => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )?.length;
};

const EmailCapture = (props: any) => {
  const {
    // @ts-ignore
    submitEmail,
    // @ts-ignore
    isSubmitting,
  } = useContext(AppContext);

  const [email, setEmail] = useState<string>("");

  const isValidEmail = validateEmail(email);

  useEffect(() => {
    if (localStorage.getItem("preloadEmail")) {
      setEmail(localStorage.getItem("preloadEmail"));
    }
  }, []);

  return (
    <>
      <Card centerHeader header="Please enter your email.">
        <div>
          <ButtonBar>
            <Input
              value={email}
              onChange={setEmail}
              type="email"
              placeholder="john.smith@company.com"
            ></Input>

            <Button
              onClick={() => submitEmail(email)}
              appearance="primary"
              disabled={!isValidEmail || isSubmitting}
            >
              Continue
            </Button>
          </ButtonBar>
        </div>
      </Card>
    </>
  );
};

export default EmailCapture;
