import { Dispatch, SetStateAction, createContext } from "react";

interface IAppContext {
  currentEmail: string;
  selectedFactors: any[];
  sortedResults: any[];
  rawFactors: object;
  factorCategories: object;
  currentView: string;
  elo: any;

  setRawFactors: Dispatch<SetStateAction<object>>;
  setFactorCategories: Dispatch<SetStateAction<object>>;
  setCurrentView: Dispatch<SetStateAction<string>>;
  setCurrentEmail: Dispatch<SetStateAction<string>>;
  setSelectedFactors: Dispatch<SetStateAction<any[]>>;
  submitResults: () => void;
  resetFactors: () => void;
  setResults: (results: any[]) => void;

  isValidEmail: boolean;
  factorsInControl: number[];
  setFactorsInControl: (factors: number[]) => void;
  resultDimensions: any[];
  user: any;
}

export const AppContext = createContext<IAppContext>({
  currentEmail: null,
  selectedFactors: [],
  sortedResults: [],
  rawFactors: {},
  factorCategories: {},
  currentView: null,
  elo: null,

  setRawFactors: () => null,
  setFactorCategories: () => null,
  setCurrentView: () => null,
  setCurrentEmail: () => null,
  setSelectedFactors: () => null,
  submitResults: () => null,
  resetFactors: () => null,
  setResults: () => null,

  isValidEmail: false,
  factorsInControl: [],
  setFactorsInControl: () => null,
  resultDimensions: [],
  user: {},
});
