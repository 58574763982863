import React, { forwardRef } from "react";

import { Animation } from "rsuite";

const Panel = forwardRef(({ children, ...props }, ref) => (
  // @ts-ignore
  <div {...props} ref={ref}>
    {children}
  </div>
));

const AnimationContainer = ({ show, children, placement }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="animation-container">
      <Animation.Bounce
        unmountOnExit
        in={show}
        // placement={placement}
        timeout={500}
      >
        {(props, ref) => (
          <Panel {...props} ref={ref}>
            {children}
          </Panel>
        )}
      </Animation.Bounce>
    </div>
  );
};

export default AnimationContainer;
