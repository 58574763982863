import "./App.css";

import Routes from "./views/Routes";

import styled from "styled-components";

const StyledContainer = styled.div`
  position: relative;
  padding: 3%;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    width: 100%;
    max-width: 800px;
  }
`;

function App() {
  return (
    <StyledContainer>
      <div className="inner">
        <Routes />
      </div>
    </StyledContainer>
  );
}

export default App;
