import { Panel } from "rsuite";
import styled from "styled-components";

const StyledCard = styled(Panel)`
  background-color: white;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);

  ${({ centerHeader }) =>
    centerHeader
      ? `
  .rs-panel-header{
    text-align: center;  
  }`
      : ""}}
`;

const Card = (props: any) => {
  return <StyledCard {...props}></StyledCard>;
};

export default Card;
