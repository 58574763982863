import { useEffect, useState } from "react";
import styled from "styled-components";
import { api } from "../_config/api";
import Card from "../components/Card";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .header-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
  }

  .text-wall {
    width: 350px;

    div {
      display: flex;
      justify-content: space-between;
    }

    .headers {
      font-weight: bold;
      margin-bottom: 16px;
    }
  }

  p {
    font-size: 14px;
    max-width: 520px;
    margin-bottom: 16px;
    margin: 0;
  }
`;

const ResultsView = () => {
  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.burnoutBot.get("/summary").then(({ data }) => {
      setResults(data);
      setIsLoading(false);
    });
  }, []);

  return (
    <Card>
      <StyledContainer>
        <div className="header-section">
          <h5>Results</h5>
        </div>

        {!isLoading && (
          <div className="text-wall">
            <div className="headers">
              <p>label</p>
              <p>score</p>
            </div>

            {results.map(([score, label]) => (
              <div key={label}>
                <p>{label}</p>
                <p>{score}</p>
              </div>
            ))}
          </div>
        )}
      </StyledContainer>
    </Card>
  );
};

export default ResultsView;
