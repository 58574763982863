class Elo {
  items: any[];
  scores: { [key: string]: number };
  last: any;
  K: number;
  drawnPairs: any[] = [];

  constructor(items) {
    this.items = items;
    this.K = 32.0;

    this.scores = {};

    for (var i = 0; i < items.length; i++) {
      this.scores[items[i]] = 1600.0;
    }
  }

  getRandomIndex() {
    return Math.floor(Math.random() * this.items.length);
  }

  drawTwo() {
    const firstIndex = this.getRandomIndex();
    let secondIndex = this.getRandomIndex();

    while (firstIndex === secondIndex) {
      secondIndex = this.getRandomIndex();
    }

    const factorOne = this.items[firstIndex];
    const factorTwo = this.items[secondIndex];

    const drawnPairs = [factorOne, factorTwo];

    this.drawnPairs.push(drawnPairs);

    return drawnPairs;
  }

  undoLast() {
    if (this.last) {
      const { winner, loser } = this.last;

      this.scores[winner.key] = winner.score;
      this.scores[loser.key] = loser.score;

      this.drawnPairs.pop();
      this.last = null;
    }
  }

  probability(winnerRating, loserRating) {
    return 1.0 / (1 + Math.pow(10.0, (loserRating - winnerRating) / 400.0));
  }

  updateScores(winner, loser) {
    const winnerRating = this.scores[winner];
    const loserRating = this.scores[loser];

    this.last = {
      winner: { key: winner, score: winnerRating },
      loser: { key: loser, score: loserRating },
      pair: this.drawnPairs[this.drawnPairs.length - 1],
    };

    const pWinner = this.probability(winnerRating, loserRating);
    const pLoser = 1.0 - pWinner;
    const newWinnerScore = winnerRating + this.K * (1.0 - pWinner);
    const newLoserScore = loserRating + this.K * (0.0 - pLoser);

    this.scores[winner] = newWinnerScore;
    this.scores[loser] = newLoserScore;
  }
}

export default Elo;
