import React, { forwardRef } from "react";

import { Animation } from "rsuite";
import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";

const Panel = forwardRef(({ children, ...props }, ref) => (
  // @ts-ignore
  <div {...props} ref={ref}>
    {children}
  </div>
));

const AnimationContainer = ({ show, children, placement }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="animation-container">
      <Animation.Bounce unmountOnExit in={show} timeout={500}>
        {(props, ref) => (
          <Panel {...props} ref={ref}>
            {children}
          </Panel>
        )}
      </Animation.Bounce>
    </div>
  );
};

const View = ({ children, viewKey }) => {
  const { currentView } = useContext(AppContext);

  return (
    <AnimationContainer show={currentView === viewKey} placement="left">
      {children}
    </AnimationContainer>
  );
};

export default View;
