import axios from "axios";

const api = {
  burnoutBot: axios.create({
    // baseURL: "https://burnout-bot-backend-dot-burnout-bot.ts.r.appspot.com/",
    baseURL: "https://reliafyapp.azurewebsites.net/api/",
  }),
};

export { api };
