import { Route, Routes } from "react-router-dom";

import EloTestView from "./EloTestView/EloTestView";
import LandingPage from "./LandingPage";
import SummaryView from "./SummaryView";

const ViewRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="/elo-test" element={<EloTestView />}></Route>
      <Route path="/summary" element={<SummaryView />}></Route>
    </Routes>
  );
};

export default ViewRoutes;
