import { AppContext } from "../../contexts/AppContext";
import CategoryIcon from "../../components/CategoryIcon";
import { Checkbox } from "rsuite";
import CreateActionPlan from "./CreateActionPlan";
import styled from "styled-components";
import { useContext } from "react";

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    flex-direction: column;

    .label {
      font-weight: bold;
      text-transform: capitalize;
      margin-bottom: 1em;
    }
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .hint {
    padding: 2em 0;
    padding-top: 1em;
    color: grey;
  }

  .instructions {
    font-size: 16px;
  }
`;

const CategoryResult = styled.div`
  margin-bottom: 3em;
`;

const StyledFactor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const ResultsArray = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid lightgrey;

  position: relative;

  .gradient {
    width: 3px;
    position: absolute;
    height: 100%;
    left: -3px;
    opacity: 50%;
  }

  p {
    margin: 0;
    position: relative;
    margin-left: 10px;
    padding-left: 5px;

    .marker {
      position: absolute;
      left: -10px;
      top: 10px;
      width: 10px;
      height: 1px;
      background-color: lightgrey;
    }
  }
`;

const FactorResults = () => {
  // @ts-ignore
  const { sortedResults, factorsInControl, setFactorsInControl } =
    useContext(AppContext);

  const categorized = sortedResults.reduce(
    (acc, factor: any, index: number) => {
      if (!acc[factor.category]) {
        acc[factor.category] = [factor];
      } else {
        acc[factor.category].push(factor);
      }

      return acc;
    },
    {}
  );

  const getCategoryGradient = (factors) => {
    return factors.map((f) => {
      if (f.score < 1600) {
        return "green";
      }

      if (f.score > 1600) {
        return "red";
      }

      return "yellow";
    });
  };

  return (
    <ColumnContainer>
      <RowContainer>
        {Object.entries(categorized).map(([label]) => (
          <div key={label} className="header">
            <CategoryIcon category={label} />
            <p className="label">{label}</p>
          </div>
        ))}
      </RowContainer>
      <ColumnContainer>
        <p className="instructions">
          Tick those factors that are in your control
        </p>
        <p className="hint">
          Factors with the highest contributions to your stress
        </p>
      </ColumnContainer>
      <RowContainer>
        {Object.entries(categorized).map(
          ([label, categoryResults]: [string, any[]]) => {
            return (
              <CategoryResult key={label}>
                {/* <div className="header">
                  <CategoryIcon category={label} />
                  <p className="label">{label}</p>
                </div> */}
                <ResultsArray>
                  <div
                    className="gradient"
                    style={{
                      background: `linear-gradient(180deg, ${getCategoryGradient(
                        categoryResults
                      ).join(", ")})`,
                    }}
                  ></div>
                  {categoryResults.map((r) => {
                    const isSelected = factorsInControl.includes(r.id);
                    return (
                      <StyledFactor key={r.label}>
                        <p>
                          <span>
                            {r.label} ({r.rank})
                          </span>

                          <span className="marker"></span>
                        </p>
                        <Checkbox
                          checked={isSelected}
                          onChange={() => {
                            if (isSelected) {
                              setFactorsInControl(
                                factorsInControl.filter((id) => id !== r.id)
                              );
                            } else {
                              setFactorsInControl([...factorsInControl, r.id]);
                            }
                          }}
                        />
                      </StyledFactor>
                    );
                  })}
                </ResultsArray>
              </CategoryResult>
            );
          }
        )}
      </RowContainer>
      <ColumnContainer>
        <p className="hint">
          Factors with the lowest contributions to your stress
        </p>
      </ColumnContainer>
      <p className="instructions">
        Set yourself three actions you will take to reduce your risk of burnout:
      </p>
      <br />
      <CreateActionPlan />
    </ColumnContainer>
  );
};

export default FactorResults;
