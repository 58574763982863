import { AppContext } from "../../contexts/AppContext";
import Card from "../../components/Card";
import styled from "styled-components";
import { useContext } from "react";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: ${({ theme }) => theme.spacings.s};
`;

const ContactDetails = (props: any) => {
  const { user } = useContext(AppContext);
  console.log(user)
  return (
    <>
      <Card centerHeader header="Thanks for your participation.">
        <StyledContainer>
          <p>
            For any further information please contact Dr Jemma King at
            <a href="mailto:jemma@bio-pa.com"> jemma@bio-pa.com</a>
          </p>
        </StyledContainer>

        <StyledContainer>
          <p>
            Your results have been emailed to you at:{" "}
            <strong>{user.email}</strong> from{" "}
            <strong>results@laurins-bot.com</strong>
          </p>
        </StyledContainer>
      </Card>
    </>
  );
};

export default ContactDetails;
