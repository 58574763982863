import ContactDetails from "./ContactDetails";
import CreateActionPlan from "./CreateActionPlan";
import { EViewKeys } from "../../types/enums";
import EmailCapture from "./EmailCapture";
import FactorSelector from "./FactorSelector";
import ReferenceRanker from "./ReferenceRanker";
import View from "../../components/View";

const EloTestView = () => {
  const views = [
    { Component: EmailCapture, viewKey: EViewKeys.emailCapture },
    { Component: FactorSelector, viewKey: EViewKeys.factorSelector },
    { Component: ReferenceRanker, viewKey: EViewKeys.referenceRanker },
    { Component: CreateActionPlan, viewKey: EViewKeys.createActionPlan },
    { Component: ContactDetails, viewKey: EViewKeys.contactDetails },
  ];

  return (
    <>
      {views.map((view) => (
        <View viewKey={view.viewKey} key={view.viewKey}>
          <view.Component />
        </View>
      ))}
    </>
  );
};

export default EloTestView;
