import "./index.css";
import "rsuite/dist/rsuite.min.css";

import { ThemeGlobalStyle, theme } from "./assets/theme";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import reportWebVitals from "./reportWebVitals";
import { AppHandler } from "./handlers/AppHandler";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <ThemeGlobalStyle />
        <AppHandler>
          <App />
        </AppHandler>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
