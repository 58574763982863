import { FaBrain } from "react-icons/fa";
import { IoBodySharp } from "react-icons/io5";
import { MdMapsHomeWork } from "react-icons/md";
import styled from "styled-components";

const categoryIcons = {
  Mind: FaBrain,
  Body: IoBodySharp,
  Environment: MdMapsHomeWork,
};

const StyledContainer = styled.div`
  opacity: 0.2;

  svg {
    font-size: 60px;
  }
`;

const CategoryIcon = ({ category }) => {
  const Icon = categoryIcons[category];

  return (
    <StyledContainer className="icon">
      <Icon />
    </StyledContainer>
  );
};

export default CategoryIcon;
